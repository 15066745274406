
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import SEOHead from '../../components/SEOHead';
import thumbnailImage from '../../public/work/aw-lab-converse-cherry/thumbnail.jpg';
import WorkVideo from '../../components/WorkVideo';
import ExploreAllWorks from '../../components/ExploreAllWorks';
import WorkParagraph from '../../components/WorkParagraph';
import DefaultSiteContainer from '../../components/DefaultSiteContainer';
import { useWorkTranslation } from '../../hooks';
import Footer from '../../components/Footer';
import EndPageCta from '../../components/EndPageCta';
import WorkImageRow from '../../components/WorkImageRow';
// Images
import Image1 from '../../public/work/aw-lab-converse-cherry/image1.png';
import Image2 from '../../public/work/aw-lab-converse-cherry/image2.png';
import Image3 from '../../public/work/aw-lab-converse-cherry/image3.png';
import Image4 from '../../public/work/aw-lab-converse-cherry/image4.png';
import Image5 from '../../public/work/aw-lab-converse-cherry/image5.png';
import Image7 from '../../public/work/aw-lab-converse-cherry/image7.png';
import Image9 from '../../public/work/aw-lab-converse-cherry/image9.png';
import Image10 from '../../public/work/aw-lab-converse-cherry/image10.png';
import Image11 from '../../public/work/aw-lab-converse-cherry/image11.png';
import Image12 from '../../public/work/aw-lab-converse-cherry/image12.png';
import Image13 from '../../public/work/aw-lab-converse-cherry/image13.png';
import Image14 from '../../public/work/aw-lab-converse-cherry/image14.png';
import Image15 from '../../public/work/aw-lab-converse-cherry/image15.png';
import Image16 from '../../public/work/aw-lab-converse-cherry/image16.png';
import Image17 from '../../public/work/aw-lab-converse-cherry/image17.png';
import Image18 from '../../public/work/aw-lab-converse-cherry/image18.png';
import Image20 from '../../public/work/aw-lab-converse-cherry/image20.png';
import Image21 from '../../public/work/aw-lab-converse-cherry/image21.png';
import Image22 from '../../public/work/aw-lab-converse-cherry/image22.png';
import Image23 from '../../public/work/aw-lab-converse-cherry/image23.png';
import Image24 from '../../public/work/aw-lab-converse-cherry/image24.png';
import Image25 from '../../public/work/aw-lab-converse-cherry/image25.png';
const meta = {
    id: 'aw-lab-converse-cherry',
    featured: true,
    date: '2024-03-29',
    thumbnail: {
        src: thumbnailImage,
        src_hq: Image7,
        metaLink: '/work/aw-lab-converse-cherry/thumbnail.jpg'
    },
    link: null,
    copy: {
        en: {
            title: 'Converse Cherry - AW LAB',
            tags: 'Product Launch, 3D, Advertising',
            description: 'Anubi was invited by AW LAB to tell the story of the three new Converse Cherry shoes in an engaging and dynamic 3D visual, which aims to capture the attention of the new generations both digitally and in stores, along with key visuals for the broader digital campaign.'
        },
        it: {
            title: 'Converse Cherry - AW LAB',
            tags: 'Product Launch, 3D, Pubblicità',
            description: 'Anubi è stata invitata da AW LAB a raccontare la storia delle tre nuove scarpe Converse Cherry in una visualizzazione 3D coinvolgente e dinamica, che mira a catturare l’attenzione delle nuove generazioni sia digitalmente che nei negozi, insieme a immagini chiave per la più ampia campagna digitale.'
        }
    }
};
export { meta };
export default function AWLABConverseCherryWorkPage() {
    const { t } = useWorkTranslation(meta);
    return (<>
      <SEOHead title={`Anubi | ${t('title')}`} description={t('description')} image={meta.thumbnail.metaLink}/>
      <DefaultSiteContainer>
        <WorkVideo ratio={[16, 9]} vimeoId='928489950'/>
        <WorkParagraph title={t('title')}>
          {t('description')}
        </WorkParagraph>
        <WorkImageRow images={[Image2, Image3]}/>
        <div style={{ height: '4px' }}/>
        <WorkImageRow images={[Image7]}/>
        <div style={{ height: '4px' }}/>
        <WorkImageRow images={[Image4, Image5]}/>
        <WorkParagraph>
          <h5 style={{ color: '#777', fontWeight: 400, textAlign: 'center' }}>Social Version</h5>
        </WorkParagraph>
        <div style={{ height: '4px' }}/>
        <WorkImageRow images={[Image9, Image10, Image11]}/>
        <WorkParagraph>
          <h5 style={{ color: '#777', fontWeight: 400, textAlign: 'center' }}>In Store Visuals</h5>
        </WorkParagraph>
        <WorkImageRow images={[Image18, Image20]}/>
        <div style={{ height: '4px' }}/>
        <WorkVideo ratio={[16, 9]} vimeoId='928756085'/>
        <WorkParagraph>
          <h5 style={{ color: '#777', fontWeight: 400, textAlign: 'center' }}>Storyboard</h5>
        </WorkParagraph>
        <WorkImageRow images={[Image1]}/>
        <WorkParagraph>
          <h5 style={{ color: '#777', fontWeight: 400, textAlign: 'center' }}>Research and Development</h5>
        </WorkParagraph>
        <WorkImageRow images={[Image13]}/>
        <div style={{ height: '4px' }}/>
        <WorkImageRow images={[Image17, Image16]}/>
        <div style={{ height: '4px' }}/>
        <WorkImageRow images={[Image25]}/>
        <div style={{ height: '4px' }}/>
        <WorkImageRow images={[Image23, Image12]}/>
        <WorkParagraph>
          <h5 style={{ color: '#777', fontWeight: 400, textAlign: 'center' }}>Process</h5>
        </WorkParagraph>
        <WorkImageRow images={[Image14]}/>
        <div style={{ height: '4px' }}/>
        <WorkImageRow images={[Image15, Image21, Image22]}/>
        <div style={{ height: '4px' }}/>
        <WorkImageRow images={[Image24]}/>
        <div style={{ height: '4px' }}/>
        <WorkVideo ratio={[16, 9]} vimeoId='928748349'/>
        <ExploreAllWorks />
      </DefaultSiteContainer>
      <EndPageCta />
      <Footer />
    </>);
}

    async function __Next_Translate__getStaticProps__1921e28a6a5__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/work/aw-lab-converse-cherry',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1921e28a6a5__ as getStaticProps }
  